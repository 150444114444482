import React, { forwardRef, useEffect, useRef, useState } from "react";

import CardUploader from "./CardUploader";
import DefaultProfilePic from "./DefaultProfilePic";
import { Image } from "../../components/Image";
import { profileCardDimensions } from "../../components/ProfileCard";
import { UpdateProfileCard } from "../../components/UpdateProfileCard";

export const useWindowSize = width => {
  const [size, setSize] = useState([450]);
  useEffect(() => {
    function updateSize() {
      return setSize([width]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [width]);
  return size;
};

const defaultScheme = {
  accent_color: "#222222",
  background_color: "black",
  career_foreground_color: "brand.gray.400",
  full_name_foreground_color: "white",
  foreground_active_color: "#222222",
};

const Card = forwardRef(
  (
    {
      handle,
      profilePic,
      setImage,
      firstName,
      lastName,
      jobTitle,
      workplace,
      logo,
      scheme = defaultScheme,
      isReadOnly = false,
    },
    ref,
  ) => {
    const {
      background_color: cardBackgroundColor,
      career_foreground_color: careerTextColor,
      full_name_foreground_color: nameTextColor,
    } = scheme;

    const cardRef = useRef(null);
    const [width] = useWindowSize(cardRef?.current?.clientWidth);
    const updateWidth = width - 40;
    const { PHOTO_WIDTH, HEIGHT } = profileCardDimensions({
      WIDTH: updateWidth,
    });

    return (
      <div ref={cardRef}>
        <UpdateProfileCard
          {...{
            user: {
              firstName: firstName,
              lastName: lastName,
              profileImage: profilePic,
            },
            trade: {
              designation: jobTitle,
              organization: workplace,
              logo: logo,
            },
            cardConfig: {
              width: updateWidth,
              template: {
                backgroundColor: cardBackgroundColor?.replace(";", ""),
                careerForegroundColor: careerTextColor?.replace(";", ""),
                fullNameForegroundColor: nameTextColor?.replace(";", ""),
              },
            },
            canvasContext: {
              callback: () => {
                return null;
              },
              mode: "react",
            },
          }}
        >
          {profilePic && width ? (
            isReadOnly ? (
              <Image
                height={HEIGHT}
                width={PHOTO_WIDTH}
                objectFit="cover"
                src={profilePic}
              />
            ) : (
              <CardUploader
                height={HEIGHT}
                width={PHOTO_WIDTH}
                ref={ref}
                profilePic={profilePic}
                handle={handle}
                setImage={setImage}
              />
            )
          ) : isReadOnly ? (
            <DefaultProfilePic height={HEIGHT} width={PHOTO_WIDTH} />
          ) : (
            <CardUploader
              height={HEIGHT}
              width={PHOTO_WIDTH}
              ref={ref}
              profilePic={profilePic}
              handle={handle}
              setImage={setImage}
            />
          )}
        </UpdateProfileCard>
      </div>
    );
  },
);

Card.displayName = "Card";

export default Card;
