import React, { FC } from "react";
import { theme, ProfileCard } from "ovou-ui";

import { IProfile, IDesign } from "types/Profile";
import { generateMediaPath } from "utils";
import { Box, Flex, Icon, Image, Text } from "components";

interface ProfilePreviewProps {
  profile?: IProfile;
  default_profile?: IProfile;
}

const getBackgroundColor = (backgroundColor: string | undefined) =>
  backgroundColor?.includes("linear")
    ? backgroundColor?.replace(";", "")
    : `#${backgroundColor || theme.palette.brand.primary.white}`;

export const ProfilePreview: FC<ProfilePreviewProps> = ({
  profile,
  default_profile,
}) => {
  const foregroundBackgroundColor = getBackgroundColor(
    profile?.design?.foreground_active_color,
  );
  const cardBackgroundColor = getBackgroundColor(
    profile?.design?.background_color,
  );

  const getValue = (key: keyof IProfile) =>
    profile?.[key] || default_profile?.[key];

  const user = {
    firstName: getValue("first_name") || "",
    lastName: getValue("last_name") || "",
    profileImage: getValue("picture") || "",
  };

  const trade = {
    designation: getValue("job_title"),
    organization: getValue("workplace"),
    logo: generateMediaPath(getValue("logo")),
  };

  const design = getValue("design") as IDesign | undefined;

  const cardConfig = {
    width: 280,
    template: {
      backgroundColor: cardBackgroundColor,
      fullNameForegroundColor: `#${
        design?.full_name_foreground_color || "defaultColor"
      }`,
      careerForegroundColor: `#${
        design?.career_foreground_color || "defaultColor"
      }`,
    },
  };

  return (
    <Box
      background={`linear-gradient(${
        theme.palette.ui.greys.grey4
      }, ${"transparent"}) padding-box`}
      borderRadius={30}
      padding="8px"
    >
      <Flex
        position="relative"
        minWidth={330}
        height={800}
        paddingX={5}
        paddingTop={120}
        paddingBottom={5}
        flexDirection="column"
        alignItems="center"
        background={theme.palette.brand.primary.white}
        borderColor="transparent"
        borderWidth={2}
        borderStyle="solid"
        borderRadius={25}
        overflow="hidden"
      >
        <Box position="absolute" top={0} width="full" height={180}>
          {profile?.background ? (
            <Image
              width="full"
              height="full"
              src={profile.background}
              alt="Cover picture"
            />
          ) : (
            <Box
              width="full"
              height="full"
              background={foregroundBackgroundColor}
            />
          )}
        </Box>

        <Flex
          position="absolute"
          flexDirection="column"
          backgroundColor="white"
          borderRadius="14px"
        >
          <ProfileCard
            user={user}
            trade={trade}
            canvasContext={{ mode: "react" }}
            cardConfig={cardConfig}
          />

          <Flex gap={2} height={12} marginTop={4}>
            <Flex
              flex={1}
              alignItems="center"
              height="full"
              padding={4}
              borderColor={theme.palette.ui.greys.grey4}
              borderWidth={1}
              borderRadius="10px"
            >
              <Box
                width="full"
                height={2}
                backgroundColor={theme.palette.ui.greys.grey4}
                borderRadius="16px"
              />
            </Flex>
            <Flex
              flex={1.3}
              alignItems="center"
              height="full"
              padding={4}
              background={foregroundBackgroundColor}
              borderColor={theme.palette.ui.greys.grey4}
              borderWidth={1}
              borderRadius="10px"
            >
              <Box
                width="full"
                height={2}
                backgroundColor={theme.palette.brand.primary.white}
                borderColor={theme.palette.ui.greys.grey4}
                borderWidth={1}
                borderRadius="16px"
              />
            </Flex>
          </Flex>

          <Flex flexDirection="column" marginTop={6}>
            <Text
              fontSize={14}
              fontWeight="extrabold"
              textColor={theme.palette.brand.primary.black}
            >
              About
            </Text>
            <Box
              width="full"
              height={2}
              marginTop={4}
              backgroundColor={theme.palette.ui.greys.grey4}
              borderRadius="16px"
            />
            <Box
              width="80%"
              height={2}
              marginTop={4}
              backgroundColor={theme.palette.ui.greys.grey4}
              borderRadius="16px"
            />
            <Box
              width="30%"
              height={2}
              marginTop={4}
              backgroundColor={theme.palette.ui.greys.grey4}
              borderRadius="16px"
            />
          </Flex>

          <Flex flexDirection="column" marginTop={6}>
            <Text
              fontSize={14}
              fontWeight="extrabold"
              textColor={theme.palette.brand.primary.black}
            >
              Social network
            </Text>
            <Flex gap={2} marginTop={4}>
              <Flex
                width="44px"
                height="44px"
                justifyContent="center"
                alignItems="center"
                backgroundColor={theme.palette.ui.greys.grey5}
                borderRadius="8px"
              >
                <Icon name="facebook" width={24} height={24} />
              </Flex>
              <Flex
                width="44px"
                height="44px"
                justifyContent="center"
                alignItems="center"
                backgroundColor={theme.palette.ui.greys.grey5}
                borderRadius="8px"
              >
                <Icon name="youtube" width={24} height={24} />
              </Flex>
              <Flex
                width="44px"
                height="44px"
                justifyContent="center"
                alignItems="center"
                backgroundColor={theme.palette.ui.greys.grey5}
                borderRadius="8px"
              >
                <Icon name="instagram" width={24} height={24} />
              </Flex>
              <Flex
                width="44px"
                height="44px"
                justifyContent="center"
                alignItems="center"
                backgroundColor={theme.palette.ui.greys.grey5}
                borderRadius="8px"
              >
                <Icon name="linkedIn" width={24} height={24} />
              </Flex>
            </Flex>
          </Flex>

          <Flex
            flexDirection="column"
            marginTop={6}
            sx={{
              svg: {
                color: theme.palette.ui.greys.grey1,
              },
            }}
          >
            <Text
              fontSize={14}
              fontWeight="extrabold"
              textColor={theme.palette.brand.primary.black}
            >
              Contact info
            </Text>
            <Flex gap={4} alignItems="center" marginTop={4}>
              <Icon name="phone" width={20} height={20} />
              <Box
                width="40%"
                height={2}
                backgroundColor={theme.palette.ui.greys.grey4}
                borderRadius="16px"
              />
            </Flex>
            <Flex gap={4} alignItems="center" marginTop={4}>
              <Icon name="website" width={20} height={20} />
              <Box
                width="80%"
                height={2}
                backgroundColor={theme.palette.ui.greys.grey4}
                borderRadius="16px"
              />
            </Flex>
            <Flex gap={4} alignItems="center" marginTop={4}>
              <Icon name="mail" width={20} height={20} />
              <Box
                width="50%"
                height={2}
                backgroundColor={theme.palette.ui.greys.grey4}
                borderRadius="16px"
              />
            </Flex>
            <Flex gap={4} alignItems="center" marginTop={4}>
              <Icon name="location" width={20} height={20} />
              <Flex flex={1} flexDirection="column" gap={3}>
                <Box
                  width="full"
                  height={2}
                  backgroundColor={theme.palette.ui.greys.grey4}
                  borderRadius="16px"
                />
                <Box
                  width="45%"
                  height={2}
                  backgroundColor={theme.palette.ui.greys.grey4}
                  borderRadius="16px"
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

const StyledFlex = ({ flex, background, borderColor, children }: any) => (
  <Flex
    flex={flex}
    alignItems="center"
    height="full"
    padding={4}
    background={background}
    borderColor={borderColor}
    borderWidth={1}
    borderRadius="10px"
  >
    {children}
  </Flex>
);

const LineBox = ({ width, margin = 0 }: any) => (
  <Box
    width={width}
    height={2}
    marginTop={margin}
    backgroundColor={theme.palette.ui.greys.grey4}
    borderRadius="16px"
  />
);

const IconRow = ({ iconName, width }: any) => (
  <Flex gap={4} alignItems="center" marginTop={4}>
    <Icon name={iconName} width={20} height={20} />
    <LineBox width={width} />
  </Flex>
);

const SocialIcon = ({ iconName }: any) => (
  <Flex
    width="44px"
    height="44px"
    justifyContent="center"
    alignItems="center"
    backgroundColor={theme.palette.ui.greys.grey5}
    borderRadius="8px"
  >
    <Icon name={iconName} width={24} height={24} />
  </Flex>
);
