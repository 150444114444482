import { useQuery } from "react-query";

import api from "../../../api";
import { IGetContactsProps } from "../types/Contact";

export enum TeamContactsSortOption {
  CREATED_ASCENDING = "created",
  CREATED_DESCENDING = "-created",
  FULL_NAME_ASCENDING = "full_name",
  FULL_NAME_DESCENDING = "-full_name",
}

interface IUseGetTeamContactsProps {
  teamId: number;
  queryAutomatically?: boolean;
  searchQuery?: string;
  tags?: number[];
  teamMembers?: number[];
  dateLowerBound?: string;
  dateUpperBound?: string;
  sort?: TeamContactsSortOption;
  offset?: number;
  limit?: number;
}

export const useGetTeamContacts = ({
  teamId,
  queryAutomatically = true,
  searchQuery = "",
  tags,
  teamMembers,
  dateLowerBound,
  dateUpperBound,
  sort,
  offset,
  limit,
}: IUseGetTeamContactsProps) => {
  return useQuery<IGetContactsProps>(
    [
      "team-contacts",
      {
        teamId,
        queryAutomatically,
        searchQuery,
        tags,
        teamMembers,
        dateLowerBound,
        dateUpperBound,
        sort,
        offset,
        limit,
      },
    ],
    async () => {
      const { data } = await api.get<IGetContactsProps>(
        `/profile/teams/${teamId}/contacts`,
        {
          params: {
            search: searchQuery?.length > 0 ? searchQuery : undefined,
            tags: tags?.toString(),
            team_members: teamMembers?.toString(),
            window_lower_bound: dateLowerBound,
            window_upper_bound: dateUpperBound,
            sort,
            offset,
            limit,
          },
        },
      );
      return data;
    },
    {
      enabled: teamId !== undefined && queryAutomatically,
      refetchOnWindowFocus: !queryAutomatically,
    },
  );
};
