import { FC, useCallback, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Spinner, VisuallyHidden } from "@chakra-ui/react";
import { AiOutlineFile } from "react-icons/ai";
import { useToasts } from "react-toast-notifications";

import { ButtonOutlined } from "components";
import { IContact, IExportContactsProps } from "../types/Contact";
import { EXPORT_CONTACT_HEADERS } from "../helpers/constants";

export const ExportContacts: FC<IExportContactsProps> = ({
  canExport,
  getContacts,
}) => {
  const { addToast } = useToasts();

  const csvLinkRef = useRef<any>();

  const [contacts, setContacts] = useState<IContact[]>([]);
  const [currentDate, setCurrentDate] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setFormattedDate = () => {
    const date = new Date();

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    setCurrentDate([year, month, day].join("-"));
  };

  const handleExportContacts = useCallback(async () => {
    if (!canExport) {
      addToast("There are no available contacts to export.", {
        appearance: "error",
      });

      return;
    }

    setIsLoading(true);
    setFormattedDate();

    addToast("Processing your request...", {
      appearance: "info",
    });

    try {
      const response = await getContacts();
      setContacts(response?.results || []);
    } catch (error) {
      addToast(
        "There was an issue exporting your contacts. Please try again.",
        {
          appearance: "error",
        },
      );
    }

    setIsLoading(false);
  }, [canExport]);

  useEffect(() => {
    if (contacts.length > 0 && csvLinkRef.current && !isLoading) {
      csvLinkRef.current.link.click();

      addToast("Your contacts have been exported successfully.", {
        appearance: "success",
      });
    }
  }, [contacts]);

  return (
    <>
      <ButtonOutlined
        minWidth="9rem"
        gap="0.5rem"
        isDisabled={isLoading}
        onClick={handleExportContacts}
      >
        {isLoading ? (
          <Spinner width="1rem" height="1rem" />
        ) : (
          <>
            <AiOutlineFile />
            Export CSV file
          </>
        )}
      </ButtonOutlined>
      <VisuallyHidden>
        <CSVLink
          ref={csvLinkRef}
          asyncOnClick={true}
          data={contacts}
          headers={EXPORT_CONTACT_HEADERS}
          filename={`contact_list_${currentDate}.csv`}
        />
      </VisuallyHidden>
    </>
  );
};
