export const EXPORT_CONTACT_HEADERS = [
  { label: "Full Name", key: "full_name" },

  { label: "Email", key: "email" },
  { label: "Workplace", key: "workplace" },
  { label: "Job Title", key: "job_title" },
  { label: "Phone", key: "phone" },
  { label: "Message", key: "message" },
];

// To prevent pagination from happening on contacts export.
export const EXPORT_CONTACT_RECORD_LIMIT = 100000;
