import React from "react";

import styled from "styled-components";

import CardHover from "./CardHover";
import defaultPicture from "../../assets/images/profile-pic-placeholder.svg";
import { generateMediaPath } from "../../utils";
import Icon from "../Icon";
import Spinner from "../Spinner";

const Wrapper = styled.div`
  #hover-el {
    transition: 300ms ease-in-out;
    visibility: hidden;
    opacity: 0;
  }
  &:hover {
    #hover-el {
      visibility: visible;
      opacity: 1;
    }
  }
  .max-lines-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: break-word;
  }
`;

const Card = ({
  state = "signIn",
  loader,
  onClick,
  hasHover = true,
  cardData,
  refetch,
  tag,
}) => {
  const {
    first_name = "",
    last_name = "",
    job_title,
    workplace = "",
    picture = "",
  } = cardData || {};

  return (
    <Wrapper
      className={`relative z-0 flex rounded-xl overflow-hidden h-48 ${
        loader ? "items-center justify-center bg-white" : "bg-black"
      }`}
      onClick={onClick}
    >
      {!loader && (
        <>
          <div className="flex-1" style={{ maxWidth: "150px" }}>
            <img
              className="w-full h-full object-cover"
              alt={first_name}
              src={`${
                picture ? generateMediaPath(picture || "") : defaultPicture
              }`}
            />
          </div>
          <div className="flex-1 flex flex-col items-start px-5 pt-8 justify-start text-white">
            <p className="font-bold text-lg max-lines-2">{first_name}</p>
            <p className="font-bold text-lg max-lines-2">{last_name}</p>
            <p className="font-light text-sm text-opacity-75 text-custom-gray-400 max-lines-2">
              {job_title} {workplace || job_title ? "at" : ""} {workplace}
            </p>
          </div>
          {hasHover && (
            <div className="text-white absolute right-0 bottom-0 m-4">
              <Icon name={state === "delete" ? "delete" : "signIn"} />
            </div>
          )}

          {hasHover && (
            <CardHover
              state={state}
              firstName={first_name}
              cardData={cardData}
              refetch={refetch}
              tag={tag}
            />
          )}
        </>
      )}
      {loader && <Spinner />}
    </Wrapper>
  );
};

export default Card;
