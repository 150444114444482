import { useGetParagonTaskHistory } from "api/paragon";
import { Text, Table, DrawerComponent } from "components";
import { useUserState } from "context/User";
import { theme } from "ovou-ui";
import React, { FC, useState } from "react";
import { getFormattedDateTime } from "utils/dateFunctions";

interface ITaskHistoryProps {
  integration: string;
  isOpen: boolean;
  onClose: () => void;
}

const columns = [
  {
    id: "status",
    Header: "Status",
    accessor: (row: any) => (
      <Text
        fontSize={13}
        fontWeight="800"
        color={
          row.status === "FAILED"
            ? theme.palette.ui.states.error
            : theme.palette.ui.states.success
        }
      >
        {row.status}
      </Text>
    ),
    maxWidth: 120,
  },
  {
    id: "time",
    Header: "Time",
    accessor: (row: any) => (
      <Text fontSize={14} fontWeight="600" color={theme.palette.ui.greys.grey2}>
        {getFormattedDateTime(row?.timestamp || Date.now())}
      </Text>
    ),
    maxWidth: 120,
  },
  {
    id: "message",
    Header: "Massage",
    accessor: (row: any) => (
      <Text fontSize={13} color={theme.palette.ui.greys.grey1}>
        {row?.output_details?.message}
      </Text>
    ),
  },
];

const TaskHistory: FC<ITaskHistoryProps> = ({
  integration,
  isOpen,
  onClose,
}) => {
  const { currentTeam } = useUserState();
  const [offset, setOffset] = useState(0);
  const {
    data,
    isLoading: isLoadingTaskHistory,
    isError,
  } = useGetParagonTaskHistory(integration, currentTeam?.id, offset);

  const taskHistories = data?.results || [];
  const handleFetchData = ({ pageIndex }: { pageIndex: number }) => {
    if (!isLoadingTaskHistory && data?.total) {
      setOffset(pageIndex * 100);
    }
  };

  return (
    <DrawerComponent isOpen={isOpen} onClose={onClose} showCloseButton>
      <Table
        columns={columns}
        data={taskHistories}
        isLoading={isLoadingTaskHistory}
        onRowSelect={false}
        fetchData={handleFetchData}
        pageCount={data?.total ? Math.ceil(data?.total / 100) : 1}
        footer={false}
        noResultsText={
          isError
            ? "Something went wrong. Please try again."
            : "No task history found"
        }
        showPageSize={false}
        showPagination={data?.total && Math.ceil(data?.total / 100) > 1}
      />
    </DrawerComponent>
  );
};

export default TaskHistory;
